import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
// import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    // private snackBar: MatSnackBar,

  ) { }

  openSnackbarErrorMessage(msg: string) {
    // this.snackBar.open(msg, '', {
    //   duration: 7500,
    //   horizontalPosition: 'right',
    //   verticalPosition: 'bottom',
    //   panelClass: ['snackBarFailure']
    // });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        retry(1),
        catchError((err => this.handleError(err))))
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      window.location.reload()
    } else if (err.status === 403) {
      // Forbidden resource, inform user
      this.openSnackbarErrorMessage('Δεν έχετε δικαιώματα για αυτή την ενέργεια');
    } else if (err.status === 500) {
      this.openSnackbarErrorMessage('Παρουσιάστηκε πρόβλημα στον Server')
    } else {
      // Generic error, maybe a bad request
      let errorMessage = '';
      if (err.error instanceof ErrorEvent) {
        // Client Side error
        errorMessage = `Error: ${err.error.message}`;
      } else {
        // Server side error
        const msg = err.error.message || err.message;
        errorMessage = `Error: ${err.status}\n${msg}`;
      }
      this.openSnackbarErrorMessage(errorMessage);
    }

    const error = err.error.message || err.statusText;
    return throwError(error);
  }
}